import { TemplateSpace } from "~/space/templates"
import TemplateSpaces from "~/store/templates.json";


export const usePlatformStore = defineStore({
	id: "platform",
	state: () => ({
		isAuth: false,
		username: "",
		authToken: "",
		userId: "",
		adminToken: "K88D9QRCEZ4F1B5GQMEGB7YTN96RI8BI",

		/* template list */
		templates: TemplateSpaces as TemplateSpace[],

		/* UI */
		showAssetLibrary: undefined,
		showAssetDetail: undefined,
		showAssetLibraryMode: "all",
		uploadProgress: 0,

		/* USER DATA */ // TODO move to exhibition store
		exhibitionData: [],
		assetLibrary: [],
		gallery: {} as { [key: string]: any }
	}),
	actions: {
		fetchTemplates() {
			// const { data } = await useFetch('/api/spaces', { method: "GET" });
			// const dataParsed = JSON.parse(JSON.stringify(data.value)) as ServerSpaceProject[]
			// const dataParsed = SpacesServerJson
			// this.templates.length = 0
			// this.templates.push(...dataParsed)
		}
	}
})
